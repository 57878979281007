<template>
    <section>
        <div>
            <p class="text-general f-24 f-600 mb-3">
                Resumen
            </p>
            <div class="box-resumen bg-white br-8 p-3">
                <div class="btn-cupon mb-2 cr-pointer" @click="verCupon">
                    <i class="icon-coupon f-15 mr-3" /> ¿Tienes un cupón?
                </div>
                <div v-if="false" class="btn-recompensas cr-pointer" @click="reclamarRecompensa">
                    <i class="icon-gem f-15 mr-2" />
                    Canjea tus recompensas
                </div>
                <div class="row mx-0 mt-3 align-items-center">
                    <i class="icon-order f-22" />
                    <div class="col-auto px-2 text-general f-600">
                        Detalle del cobro
                    </div>
                </div>
                <div class="row mx-0 f-15">
                    <div class="col-auto text-general pl-4 pr-0">
                        Productos ({{ infoCarrito.cant_productos }}) ({{ infoCarrito.cant_unidades }} Und)
                    </div>
                    <div class="col-auto text-general f-600 ml-auto">
                        {{ convertMoney( infoCarrito.val_productos) }}
                    </div>
                </div>
                <div class="row mx-0 f-15 mt-2">
                    <div class="col-auto text-general pl-4 pr-0">
                        Descuento Promos
                    </div>
                    <div class="col-auto text-alert f-600 ml-auto">
                        {{ convertMoney(infoCarrito.val_descuento) }}
                    </div>
                </div>
                <div v-if="infoCarrito.val_descuento_lista > 0" class="row mx-0 f-15 mt-2">
                    <div class="col-auto text-general pl-4 pr-0">
                        Descuento Lista
                    </div>
                    <div class="col-auto text-alert f-600 ml-auto">
                        {{ convertMoney(infoCarrito.val_descuento_lista) }}
                    </div>
                </div>
                <div v-if="infoCarrito.id_cupon != null" class="row mx-0 f-15 mt-2">
                    <div class="col-auto text-purple pl-4 pr-0 d-middle">
                        <i class="icon-coupon f-20" /> 
                        <span class="f-600">{{ infoCarrito.codigo_cupon }} </span>
                        <i class="icon-cancel text-alert f-20 cr-pointer" @click="removerCupon" />
                    </div>
                    <div class="col-auto text-alert f-600 ml-auto">
                        {{ convertMoney(infoCarrito.val_cupon) }}
                    </div>
                </div>
                <div v-if="infoCarrito.val_nomina > 0" class="row mx-0 f-15 mt-2">
                    <div class="col-auto text-general pl-4 pr-0">
                        <i class="icon-handshake f-20" /> Convenio de nómina
                    </div>
                    <div class="col-auto text-alert f-600 ml-auto">
                        {{ convertMoney(infoCarrito.val_nomina) }}
                    </div>
                </div>
                <div class="row mx-0 f-15 mt-2">
                    <div class="col-auto text-general f-600 pl-4 pr-0">
                        SUB-TOTAL
                    </div>
                    <div class="col-auto text-general f-600 ml-auto">
                        {{ convertMoney(infoCarrito.subtotal) }}
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div v-if="infoCarrito.val_impuestos > 0" class="row mx-0 f-15">
                    <div class="col-auto text-general pl-4 pr-0">
                        Impuestos
                    </div>
                    <div class="col-auto text-general f-600 ml-auto">
                        {{ convertMoney(infoCarrito.val_impuestos) }}
                    </div>
                </div>
                <div class="row mx-0 f-15 mt-2">
                    <div class="col-auto text-general pl-4 pr-0">
                        Domicilio
                    </div>
                    <div v-if="infoCarrito.val_domicilio == 0" class="col-auto text-alert f-600 ml-auto">
                        Gratis
                    </div>
                    <div v-else class="col-auto text-general f-600 ml-auto">
                        {{ convertMoney(infoCarrito.val_domicilio) }}
                    </div>
                </div>
                <div v-show="infoCarrito.val_domicilio > 0" class="btn-cupon mb-2 cr-pointer">
                    <p class="mx-3">
                        El domicilio es <b>Gratis</b>  por compras superiores a  <b>{{ convertMoney(infoCarrito.domicilio_gratis) }}</b>
                    </p>
                </div>
                <hr class="bg-gris ml-4 mr-3" />
                <div class="row mx-0 f-15 mt-2">
                    <div class="col-auto text-general pl-4 pr-0 f-600">
                        TOTAL
                    </div>
                    <div class="col-auto text-general f-600 ml-auto">
                        {{ convertMoney(infoCarrito.val_total) }}
                    </div>
                </div>
                
                <div v-if="infoCarrito.porcentajePedido < 100" class="row mx-3 bg-fondo2 br-20 mt-3" style="height:10px;">
                    <div class="bg-general h-100 br-20" :style="`width:${infoCarrito.porcentajePedido}%;`" />
                </div>
                <p v-if="infoCarrito.porcentajePedido < 100" class="text-center text-general f-12 mt-2">
                    Faltan {{ convertMoney( infoCarrito.pedido_minimo - infoCarrito.subtotal ) }} para completar el pedido mínimo
                </p>
                <div v-if="showOptions == 1">
                    <div class="bg-general mt-2 mx-3 text-center cr-pointer text-white d-flex justify-content-start a-center px-1 mx-0 br-8 f-15" style="height:44px;" @click="continuarPedidos">
                        <div class="ml-2 mr-2">
                            Continuar
                        </div>  
                        <div class="ml-auto tres-puntos mr-1">
                            {{ convertMoney(infoCarrito.subtotal) }}
                        </div>
                    </div>
                    <div class="btn-outline mx-3 mt-4" @click="vaciarCarrito">
                        Vaciar carrito
                    </div>
                </div>
                <div v-else>
                    <p class="text-general f-14 f-600 text-center mt-3">
                        ¿Te hizo falta algo más en tu pedido?
                    </p>
                    <div class="btn-outline my-2" @click="$router.push({name: 'carrito-compras'})">
                        Regresar al carrito
                    </div>
                    <div v-loading="cargandoCompuesto" class="row mx-0 mt-2 bg-white row-button j-center">
                        <div class="col-xl-12 col-lg-12 col-md-8 col-sm-8 col-8 px-0">
                            <div class="btn-general f-14 text-white br-10" style="height:44px;" @click="continuarPedidos">
                                Hacer Pedido
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalVaciarCarrito" titulo="Vaciar carrito" no-aceptar adicional="Vaciar" tamano="moda-sm" @adicional="vaciarContenidoCarrito">
            <div class="row mx-0 j-center my-3">
                <div class="col-auto">
                    ¿Desea vaciarlo?
                </div>
            </div>
        </modal>
        <modal-cupon ref="modalCupon" @detalleCupon="modalCuponDetalle" />
        <modal-detalle-cupon ref="modalDetalleCupon" :detalle-cupon="cupon_detalle" @irAtras="verCupon" @aplicarCupon="anadirCuponACarrito" />
        <modal-canjear-recompensa ref="modalCanjear" />
        <modal-prod-insuficiente ref="modalProdInsuficiente" :productos-agotados="prodInsuficiente" @eliminarPedido="carritoActualizado" />
        <modal-origen-productos ref="modalOrigenProductos" :productos="productosOrigen" />
    </section>
</template>
<script>
import Carrito from '~/services/carrito/carrito'
export default {
    components: {
        modalCupon: () => import('../partials/modalCupon'),
        modalCanjearRecompensa: () => import('../partials/modalCanjearRecompensa'),
        modalProdInsuficiente: () => import('../partials/modalProdInsuficiente'),
        modalDetalleCupon: () => import('../partials/modalDetalleCupon'),
        modalOrigenProductos: () => import('../partials/modalOrigenProductos'),
    },
    props: {
        infoCarrito: {
            type: Object,
            required: true
        },
        cargando: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            cargandoResumen:false,
            cant_carrito: 15,
            // agotados: {},
            prodInsuficiente: [],
            productosOrigen: [],
            cupon_detalle: {},
            // infoCarrito: {},
            porcentajePedido: null,
            boolAgotados: false
        }
    },
    computed:{
        showOptions(){
            const name = this.$route.name
            if(name == "carrito-compras") return 1
            return 2
        },
        cargandoCompuesto(){
            if(this.cargando || this.cargandoResumen) return true
            return false
        }
    },

    // mounted(){
    //     this.getResumenCarrito()
    // },
    methods: {
        vaciarCarrito(){
            this.$refs.modalVaciarCarrito.toggle();
        },
        verCupon(){
            this.$refs.modalCupon.toggle();
        },
        reclamarRecompensa(){
            this.$refs.modalCanjear.toggle();
        },
        modalCuponDetalle(detalle_cupon){
            this.cupon_detalle = detalle_cupon
            this.$refs.modalDetalleCupon.toggle();
        },
        async continuarPedidos(){
            try {
                this.cargandoResumen = true
                const { data } = await Carrito.validarStockPedidos()
                this.boolAgotados = data.agotados.agotado

                if(this.boolAgotados == true){
                    this.prodInsuficiente = data.agotados.productos
                    this.$refs.modalProdInsuficiente.toggle();
                } else if(this.showOptions == 1){
                    await this.getOrigenProductos()
                    this.$refs.modalOrigenProductos.toggle();
                    //this.$router.push({name: 'carrito-compras.checkout'});
                }else{
                    this.$emit('realizarPedido')
                }
            } catch (error){
                this.errorCatch(error)
            }
            finally {
                this.cargandoResumen = false
            }
        },

        async getOrigenProductos(){
            try {
                const { data } = await Carrito.getOrigenProductos()
                this.productosOrigen = data
            } catch (error){
                this.errorCatch(error)
            }

        },

        hacerPedido(){
            if(this.fuera_cobertura){
                return
            }
            this.$router.push({name: 'carrito-compras.pedido-creado'});
        },
        async anadirCuponACarrito(item){
            try {
                const { data } = await Carrito.addCuponCarrito(item)
                this.notificacion('Éxito', data.mensaje, 'success')
                this.$emit('carritoActualizado')
            } catch (error){
                return this.errorCatch(error)
            }
        },
        async removerCupon(){
            try {
                const { data } = await Carrito.borrarCuponDeCarrito()
                this.notificacion('Éxito', data.mensaje, 'success')
                this.$emit('carritoActualizado')
            } catch (error){
                return this.errorCatch(error);
            }
        },
        async vaciarContenidoCarrito(){
            try {
                const { data } = await Carrito.vaciarContenidoCarrito()
                this.notificacion('Éxito', data.mensaje, 'success')
                this.$emit('carritoActualizado')
                this.$refs.modalVaciarCarrito.toggle();

                this.$usuario.cant_productos_carrito = 0;
            } catch (error){
                return this.errorCatch(error);
            }
        },
        carritoActualizado(){
            this.$emit('carritoActualizado')
        }
    }
}
</script>
<style lang="scss" scoped>
.box-resumen{
    box-shadow: 0px 3px 6px #0000000D;
    .btn-cupon{
        height: 44px;
        display: flex;
        align-items: center;
        color: #612AD5;
        justify-content: center;
        background-color: #E1DBEF;
        border-radius: 8px;
    }
    .btn-recompensas{
        height: 44px;
        display: flex;
        align-items: center;
        color: #29D884;
        justify-content: center;
        background-color: #DBF9EA;
        border-radius: 8px;
    }
}

.icon-handshake::before{
    margin-left: 0px !important;
}

@media (min-width: 300px) and (max-width: 985px){
    section.custom-scroll.overflow-auto{
        height: calc(100vh - 150px) !important;
    }
}
</style>